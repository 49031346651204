import axios from 'axios';
import React, {Component} from 'react';
import PageVisibility from 'react-page-visibility';
import ActiveCradcModal from "./components/ActiveCradcModal";
import DistributionModal from "./components/DistributionModal";
import UsageReportModal from './components/UsageReportModal'

import logo from './img/ccss-white.png';
const PRODUCTION = process && process.env && process.env.NODE_ENV !== 'development';
const API_HOST = PRODUCTION ? 'https://reporting.computing.socialsciences.cornell.edu/api' : 'http://localhost:9191';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInterface: false,
            authenticated: false,
            authorized: false,
            user: {}
        }
    }

    componentDidMount() {
        this.callAPI();
    };

    callAPI = () => {
        axios({
            method: 'GET',
            url: API_HOST + '/whoami',
            withCredentials: true
        }).then(res => {
            this.setState({
                showInterface: true,
                authenticated: true,
                authorized: true,
                user: res.data
            });
        }).catch(error => {
            if (error.response.status === 403) {
                this.setState({
                    showInterface: true,
                    authenticated: true,
                    authorized: false
                });
            } else if (error.response.status === 401) {
                this.setState({
                    showInterface: true,
                    authenticated: false,
                    authorized: false
                });
            }
        });
    };

    handleVisibilityChange = (visible) => {
        if (visible) {
            this.callAPI();
        }
    };

    render() {
        return (
            <React.Fragment>
                <PageVisibility onChange={this.handleVisibilityChange}/>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
                    <a className="navbar-brand" href="/"><img src={logo} height="50" className="d-inline-block align-top" alt="CCSS-RS Reporting"/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="/">Reporting Home <span className="sr-only">(current)</span></a>
                            </li>
                        </ul>
                        {this.state.authenticated && this.state.authorized ?
                            <span className="navbar-text">
                                {this.state.user.fullname} ({this.state.user.netid}) | <a href={API_HOST + '/logout'}>Logout</a>
                        </span> : null}
                        {this.state.authenticated && !this.state.authorized ?
                            <span className="navbar-text">
                               <a href={API_HOST + '/logout'}>Logout</a>
                        </span> : null}
                    </div>
                </nav>
                <main role="main" className="container">
                    {(this.state.authenticated && this.state.authorized) || (!this.state.authenticated && !this.state.authorized) ?
                        <div className="masthead text-center">
                            <h1>Weclome to CCSS-RS Reporting</h1>
                            <p className="lead">This utility provides insight on the usage of CCSS-RS Computing Servers.</p>
                        </div> : null}
                    {this.state.showInterface && this.state.authenticated && this.state.authorized ?
                        <React.Fragment>
                            <h2 className="text-center">Usage Reporting</h2>
                            <div className="row">
                                <div className="col-4 offset-2">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">Usage Report</h5>
                                            <button className="btn btn-outline-dark" data-toggle="modal" data-target="#usageReportModal">
                                                Configure
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">Usage Distribution Bundle</h5>
                                            <button className="btn btn-outline-dark" data-toggle="modal" data-target="#distributionModal">
                                                Configure
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="text-center mt-5">CRADC Reporting</h2>
                            <div className="row mt-3">
                                <div className="col-4 offset-2">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h5 className="card-title">CRADC Affiliation Report</h5>
                                            <button className="btn btn-outline-dark" data-toggle="modal" data-target="#cradcAffiliationModal">
                                                Configure
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : null}
                    {this.state.authenticated && !this.state.authorized ?
                        <div className="row">
                            <div className="col text-center">
                                <h1>You do not have access to this system.</h1>
                                <h2>Please contact: <a href="mailto:CCSS-ResearchSupport@cornell.edu">CCSS-ResearchSupport@cornell.edu</a></h2>
                            </div>
                        </div> : null}
                    {this.state.showInterface && !this.state.authenticated ?
                        <div className="row">
                            <div className="col text-center">
                                <a href={API_HOST + '/auth'} className="btn btn-lg btn-primary">Sign-in with Cornell NetID</a>
                            </div>
                        </div>
                        : null}
                </main>
                <UsageReportModal host={API_HOST + '/usage_report'}/>
                <DistributionModal host={API_HOST + '/distribution'}/>
                <ActiveCradcModal host={API_HOST + '/cradc_active_lookup'}/>
            </React.Fragment>
        );
    }
}
export default App;