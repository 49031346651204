import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {toast, ToastContainer} from "react-toastify";
class DistributionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'BOTH',
            basePeriodStart: new Date(),
            basePeriodEnd: new Date(),
            reportingPeriodStart: new Date(),
            reportingPeriodEnd: new Date()
        }
    }
    reset = () => {
        this.setState({
            domain: 'BOTH',
            filename: '',
            basePeriodStart: new Date(),
            basePeriodEnd: new Date(),
            reportingPeriodStart: new Date(),
            reportingPeriodEnd: new Date()
        });
    };
    handleBaseStartChange = (date) => {
        this.setState({
            basePeriodStart: date
        });
    };
    handleBaseEndChange = (date) => {
        this.setState({
            basePeriodEnd: date
        });
    };
    handleReportingStartChange = (date) => {
        this.setState({
            reportingPeriodStart: date
        });
    };
    handleReportingEndChange = (date) => {
        this.setState({
            reportingPeriodEnd: date
        });
    };
    handleDomainChange = (event) => {
        this.setState({
            domain: event.target.value
        });
    };
    download = () => {
        toast.success("Report request opened in new tab. Please leave open until report is generated", {
            position: toast.POSITION.TOP_CENTER
        });
        window.open(this.generateLink());
    };
    generateLink = () => {
        let basePeriodStart = (this.state.basePeriodStart.getMonth() + 1) + "-" + this.state.basePeriodStart.getDate() + "-" + this.state.basePeriodStart.getFullYear();
        let basePeriodEnd = (this.state.basePeriodEnd.getMonth() + 1) + "-" + this.state.basePeriodEnd.getDate() + "-" + this.state.basePeriodEnd.getFullYear();
        let reportingPeriodStart = (this.state.reportingPeriodStart.getMonth() + 1) + "-" + this.state.reportingPeriodStart.getDate() + "-" + this.state.reportingPeriodStart.getFullYear();
        let reportingPeriodEnd = (this.state.reportingPeriodEnd.getMonth() + 1) + "-" + this.state.reportingPeriodEnd.getDate() + "-" + this.state.reportingPeriodEnd.getFullYear();
        return this.props.host + '?basePeriodStart=' + basePeriodStart + '&basePeriodEnd=' + basePeriodEnd + '&reportPeriodStart='
            + reportingPeriodStart + '&reportPeriodEnd=' + reportingPeriodEnd;
    };
    render() {
        return (
            <React.Fragment>
                <div id="distributionModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Generate Distribution Reporting Bundle</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row mt-3">
                                        <div className="col-4 offset-2 mr-5">
                                            <label><strong>Base Period Start:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.basePeriodStart}
                                                startDate={this.state.basePeriodStart}
                                                endDate={this.state.basePeriodEnd}
                                                onChange={this.handleBaseStartChange}
                                                maxDate={this.state.basePeriodEnd}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label><strong>Base Period End:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.basePeriodEnd}
                                                startDate={this.state.basePeriodStart}
                                                endDate={this.state.basePeriodEnd}
                                                onChange={this.handleBaseEndChange}
                                                minDate={this.state.basePeriodStart}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-4 offset-2 mr-5">
                                            <label><strong>Reporting Period Start:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.reportingPeriodStart}
                                                startDate={this.state.reportingPeriodStart}
                                                endDate={this.state.reportingPeriodEnd}
                                                onChange={this.handleReportingStartChange}
                                                maxDate={this.state.reportingPeriodEnd}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label><strong>Reporting Period End:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.reportingPeriodEnd}
                                                startDate={this.state.reportingPeriodStart}
                                                endDate={this.state.reportingPeriodEnd}
                                                onChange={this.handleReportingEndChange}
                                                minDate={this.state.reportingPeriodStart}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={this.download} data-dismiss="modal">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer autoClose={false} closeButton={false}/>
            </React.Fragment>
        )
    }
}
export default DistributionModal;
