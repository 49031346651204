import axios from 'axios';
import FileDownload from 'js-file-download';
import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {toast, ToastContainer} from "react-toastify";

class UsageReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'BOTH',
            filename: '',
            startDate: new Date(),
            endDate: new Date()
        }
    }

    reset = () => {
        this.setState({
            domain: 'BOTH',
            filename: '',
            startDate: new Date(),
            endDate: new Date()
        });
    };

    handleStartChange = (date) => {
        this.setState({
            startDate: date
        });
    };

    handleEndChange = (date) => {
        this.setState({
            endDate: date
        });
    };

    handleDomainChange = (event) => {
        this.setState({
            domain: event.target.value
        });
    };

    handleFilenameChange = (event) => {
        this.setState({
            filename: event.target.value
        })
    };

    download = () => {
        const t = toast.success("Report Requested! Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        axios({
            method: 'GET',
            url: this.generateLink(),
            withCredentials: true
        }).then((response) => {
            toast.update(t, {
                render: "Report Generated!",
                type: toast.TYPE.SUCCESS,
                className: 'rotateY animated',
                autoClose: 5000
            });
            FileDownload(response.data, this.state.filename === '' ? 'UsageReport.csv' : this.state.filename + '.csv');
            this.reset()
        });
    };
    generateLink = () => {
        let start = (this.state.startDate.getMonth() + 1) + "-" + this.state.startDate.getDate() + "-" + this.state.startDate.getFullYear();
        let end = (this.state.endDate.getMonth() + 1) + "-" + this.state.endDate.getDate() + "-" + this.state.endDate.getFullYear();
        return this.props.host + '?domain=' + this.state.domain + '&start=' + start + '&end=' + end;
    };
    render() {
        return (
            <React.Fragment>
                <div id="usageReportModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Generate Usage Report</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row pt-4 pb-3">
                                        <div className="col-8 offset-2">
                                            <label><strong>Select Domain(s):</strong></label>
                                        </div>
                                        <div className="col-8 offset-2">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="domain" id="domain1" value="BOTH" checked={this.state.domain === 'BOTH'}
                                                       onChange={this.handleDomainChange}/>
                                                <label className="form-check-label" htmlFor="domain1">
                                                    Research &amp; CRADC
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="domain" id="domain2" value="RESEARCH" checked={this.state.domain === 'RESEARCH'}
                                                       onChange={this.handleDomainChange}/>
                                                <label className="form-check-label" htmlFor="domain2">
                                                    Research
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="domain" id="domain3" value="CRADC" checked={this.state.domain === 'CRADC'}
                                                       onChange={this.handleDomainChange}/>
                                                <label className="form-check-label" htmlFor="domain3">
                                                    CRADC
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-3 offset-2 mr-5">
                                            <label><strong>Start Date:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleStartChange}
                                                maxDate={this.state.endDate}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label><strong>End Date:</strong></label><br/>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onChange={this.handleEndChange}
                                                minDate={this.state.startDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6 offset-2">
                                            <label><strong>Filename:</strong>
                                                <small> (optional)</small>
                                            </label>
                                            <input className="form-control" type="text" placeholder="UsageReport.csv" value={this.state.filename} onChange={this.handleFilenameChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={this.download} data-dismiss="modal">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer autoClose={false} closeButton={false}/>
            </React.Fragment>
        )
    }
}
export default UsageReportModal;