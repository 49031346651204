import FileDownload from 'js-file-download';
import React, {Component} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {toast, ToastContainer} from "react-toastify";

class ActiveCradcModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filename: '',
        }
    }

    handleFilenameChange = (event) => {
        this.setState({
            filename: event.target.value
        })
    };

    download = () => {
        const t = toast.success("Report Requested! Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        const formData = new FormData();
        const file = document.querySelector('#raw_report').files[0];
        formData.append("accounts_file", file);

        fetch(this.props.host, {
            method: 'POST',
            body: formData
        }).then((response) => {

            if (response.ok) {
                toast.update(t, {
                    render: "Report Generated!",
                    type: toast.TYPE.SUCCESS,
                    className: 'rotateY animated',
                    autoClose: 5000
                });
                FileDownload(response.data,this.state.filename === '' ? 'CRADC_Affiliation_Report.csv' : this.state.filename + '.csv');
            } else {
                toast.update(t, {
                    render: "An unknown error has occurred!",
                    type: toast.TYPE.ERROR,
                    className: 'rotateY animated',
                    autoClose: 5000
                });
            }
        });

    };

    render() {
        return (
            <React.Fragment>
                <div id="cradcAffiliationModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Generate CRADC Affiliation Report</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <strong>Please upload the Agiloft Report in CSV Format.</strong>
                                    <div className="row">
                                        <div className="col">
                                            <input type="file" id="raw_report"/>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <label><strong>Filename:</strong>
                                                <small> (optional)</small>
                                            </label>
                                            <input className="form-control" type="text" placeholder="CRADC_Affiliation_Report.csv" value={this.state.filename} onChange={this.handleFilenameChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={this.download} data-dismiss="modal">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer autoClose={false} closeButton={false}/>
            </React.Fragment>
        )
    }
}
export default ActiveCradcModal;